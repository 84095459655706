<template>
  <div>
    <el-select ref="selectA" v-model="selectValue">
      <el-option
          v-for="(item, index) in option"
          :key="index"
          :label="item.name"
          :value="item.name"></el-option>
    </el-select>
    <el-button></el-button>
  </div>
</template>

<script>
export default {
  data(){
    return{
      selectValue: '',
      option: [
        {id: 'grt', name:'郭瑞婷', year:'1995' },
        {id: 'gsl', name:'郭思鲁', year:'1994' },
        {id: 'gjy', name:'郭敬垚', year:'2016' },
      ]
    }
  },
  watch:{
    selectValue(newVal, oldVal){
    }
  },
  mounted(){
  }
}
</script>

<style>
</style>
